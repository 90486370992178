// import React, { useState } from "react";
// import { Link } from "react-router-dom";

// import DefaultModal from "../Modal";

// const Product = ({ products, addToCartProduct, addToWishListProduct }) => {
//   const ClickHandler = () => {
//     window.scrollTo(10, 0);
//   };

//   const [open, setOpen] = React.useState(false);

//   function handleClose() {
//     setOpen(false);
//   }

//   const [state, setState] = useState({});

//   const handleClickOpen = (item) => {
//     setOpen(true);
//     setState(item);
//   };
//   return (
//     <section className="product-area section-padding">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-6 offset-lg-3">
//             <div className="section-title">
//               <h2>
//                 100% Fresh <span>Honey</span>
//               </h2>
//               <p>
//                 Lorem Ipsum is simply dummy text of the printing and typesetting
//                 industry has been the industry's standard consectetur
//                 adipisicing elit.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="product-wrap">
//           <div className="row align-items-center">
//             {products.length > 0 &&
//               products.slice(0, 8).map((product, pitem) => (
//                 <div className="col-lg-3 col-md-6 col-sm-12 col-12" key={pitem}>
//                   <div className="product-item">
//                     <div className="product-img">
//                       <img src={product.proImg} alt="" />
//                       <ul>
//                         <li>
//                           <button
//                             data-bs-toggle="tooltip"
//                             data-bs-html="true"
//                             title="Add to Cart"
//                             onClick={() => addToCartProduct(product)}
//                           >
//                             <i className="fi flaticon-shopping-cart"></i>
//                           </button>
//                         </li>
//                         <li>
//                           <button
//                             data-bs-toggle="tooltip"
//                             data-bs-html="true"
//                             title="Quick View"
//                             onClick={() => handleClickOpen(product)}
//                           >
//                             <i className="fi ti-eye"></i>
//                           </button>
//                         </li>
//                         <li>
//                           <button
//                             data-bs-toggle="tooltip"
//                             data-bs-html="true"
//                             title="Add to Wishlist"
//                             onClick={() => addToWishListProduct(product)}
//                           >
//                             <i className="fi flaticon-like"></i>
//                           </button>
//                         </li>
//                       </ul>
//                       <div className="offer-thumb">
//                         <span>{product.offer}</span>
//                       </div>
//                     </div>
//                     <div className="product-content">
//                       <h3>
//                         <Link
//                           onClick={ClickHandler}
//                           to={`/product-single/${product.id}`}
//                         >
//                           {product.title}
//                         </Link>
//                       </h3>
//                       <div className="product-btm">
//                         <div className="product-price">
//                           <ul>
//                             <li>${product.price}</li>
//                             <li>${product.delPrice}</li>
//                           </ul>
//                         </div>
//                         <div className="product-ratting">
//                           <ul>
//                             <li>
//                               <i className="fa fa-star" aria-hidden="true"></i>
//                             </li>
//                             <li>
//                               <i className="fa fa-star" aria-hidden="true"></i>
//                             </li>
//                             <li>
//                               <i className="fa fa-star" aria-hidden="true"></i>
//                             </li>
//                             <li>
//                               <i className="fa fa-star" aria-hidden="true"></i>
//                             </li>
//                             <li>
//                               <i className="fa fa-star" aria-hidden="true"></i>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//           </div>
//         </div>
//       </div>
//       <DefaultModal
//         addToCartProduct={addToCartProduct}
//         open={open}
//         onClose={handleClose}
//         product={state}
//       />
//     </section>
//   );
// };

// export default Product;
import React from 'react';
import { Link } from 'react-router-dom';

const Product = ({ products, addToCartProduct, addToWishListProduct }) => {
  return (
    <section className="new-arrivals">
      <div className="container">
        <h2>NEW ARRIVALS</h2>
        <div className="product-grid">
          {products.slice(0, 4).map((product, index) => (
            <div className="product-item" key={index}>
              <img src={product.image} alt={product.name} />
              <p className="product-code">Mã quà: {product.code}</p>
              <p className="product-price">{product.price.toLocaleString()}₫</p>
              <div className="product-buttons">
                <button onClick={() => addToCartProduct(product)} className="btn-cart">Add to Cart</button>
                <button onClick={() => addToWishListProduct(product)} className="btn-wishlist">Add to Wishlist</button>
              </div>
            </div>
          ))}
        </div>
        <Link to="/new-arrivals" className="btn theme-btn">View All</Link>
      </div>
    </section>
  );
};

export default Product;