// import React, { Component } from "react";
// import { Link } from 'react-router-dom'

// import hero2 from '../../images/slider/img-1.png'
// import hero3 from '../../images/slider/img-2.png'
// import hero4 from '../../images/slider/img-3.png'




// class Hero extends Component {
//     render() {

//         return (
//             <section className="hero hero-style-1">
//                 <div className="hero-slider">
//                     <div className="slide">
//                         <div className="container">
//                             <div className="row">
//                                 <div className="col col-lg-5 slide-caption">
//                                     <div className="slide-title">
//                                         <h2><span>Fresh</span> Organic <span>Honey</span></h2>
//                                     <div className="btns">
//                                         <Link to="/shop" className="btn theme-btn">Shop Now <i className="fa fa-angle-double-right"></i></Link>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="right-image">
//                             <div className="simg-1"><img src={hero2} alt="slide-img"/></div>
//                             <div className="simg-2"><img src={hero3} alt="slide-img"/></div>
//                         </div>
//                         <div className="hero-shape-img"><img src={hero4} alt="slide-img"/></div>
//                     </div>
//                 </div>
//               </div>
//             </section>
//         )
//     }
// }

// export default Hero;
import React from 'react';
import { Link } from 'react-router-dom';
import hero from '../../images/hero-image.png'

const Hero = () => {
  return (
    <section className="hero">
      <div className="container">
        <div className="hero-content">
          <div className="hero-text">
            <h1>FIND GIFT THAT MATCHES YOUR PERSONALIZED EXPERIENCE</h1>
            <p>All Handmade with Care, MeGift Delivers Joy in Every Box</p>
            <div className="hero-metrics">
              <div className="metric">
                <span className="metric-value">200+</span>
                <span className="metric-label">Handmade Products</span>
              </div>
              <div className="metric">
                <span className="metric-value">2,000+</span>
                <span className="metric-label">High-Quality Products</span>
              </div>
              <div className="metric">
                <span className="metric-value">30,000+</span>
                <span className="metric-label">Happy Customers</span>
              </div>
            </div>
            <Link to="/shop" className="btn theme-btn">Shop Now <i className="fa fa-angle-double-right"></i></Link>
          </div>
          <div className="hero-image">
            <img src={hero} alt="Gift Box" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;