// import React, { Component } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// import test1 from '../../images/testimonial/1.png'
// import test2 from '../../images/testimonial/3.png'
// import test3 from '../../images/testimonial/2.png'




// class Testimonial extends Component {

    
//     render() {
//         var settings = {
//             dots: false,
//             arrows: true,
//             speed: 1200,
//             slidesToShow: 1,
//             slidesToScroll: 1,
//             autoplay: true,
//             autoplaySpeed: 2500,
//             fade: true
//         };

//         return (
//             <section className="testimonial-area section-padding">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-lg-6 offset-lg-3">
//                             <div className="section-title">
//                                 <h2>Client <span>Testimonial</span></h2>
//                                 <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the
//                                     industry's standard consectetur adipisicing elit.</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="testimonial-wrap">
//                         <div className="testimonial-active">
//                             <Slider {...settings}>
//                                 <div className="testimonial-item">
//                                     <div className="testimonial-img">
//                                         <img src={test1} alt=""/>
//                                         <div className="t-quote">
//                                             <i className="fi flaticon-left-quote"></i>
//                                         </div>
//                                     </div>
//                                     <div className="testimonial-content">
//                                         <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry
//                                             has been the industry's standard dummy text ever since the 1500s unknown
//                                             printer took a galley of type and scrambled it to make a type specimen
//                                             book has survived not has been the industry's standard consectetur adipisicing elit
//                                             only five centuries the industry's standard consectetur.</p>
//                                         <div className="testimonial-author">
//                                             <h3>Rachel Matthews</h3>
//                                             <span>CEO, Deixfes</span>
//                                         </div>
//                                         <div className="t-content-quote">
//                                             <i className="fi flaticon-left-quote"></i>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="testimonial-item">
//                                     <div className="testimonial-img">
//                                         <img src={test2} alt=""/>
//                                         <div className="t-quote">
//                                             <i className="fi flaticon-left-quote"></i>
//                                         </div>
//                                     </div>
//                                     <div className="testimonial-content">
//                                         <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry
//                                             has been the industry's standard dummy text ever since the 1500s unknown
//                                             printer took a galley of type and scrambled it to make a type specimen
//                                             book has survived not has been the industry's standard consectetur adipisicing elit
//                                             only five centuries the industry's standard consectetur.</p>
//                                         <div className="testimonial-author">
//                                             <h3>David Warner</h3>
//                                             <span>CEO, TBR</span>
//                                         </div>
//                                         <div className="t-content-quote">
//                                             <i className="fi flaticon-left-quote"></i>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="testimonial-item">
//                                     <div className="testimonial-img">
//                                         <img src={test3} alt=""/>
//                                         <div className="t-quote">
//                                             <i className="fi flaticon-left-quote"></i>
//                                         </div>
//                                     </div>
//                                     <div className="testimonial-content">
//                                         <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry
//                                             has been the industry's standard dummy text ever since the 1500s unknown
//                                             printer took a galley of type and scrambled it to make a type specimen
//                                             book has survived not has been the industry's standard consectetur adipisicing elit
//                                             only five centuries the industry's standard consectetur.</p>
//                                         <div className="testimonial-author">
//                                             <h3>Ken Williamson</h3>
//                                             <span>CEO, Bexim</span>
//                                         </div>
//                                         <div className="t-content-quote">
//                                             <i className="fi flaticon-left-quote"></i>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </Slider>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         )
//     }
// }

// export default Testimonial;
import React, { useState } from 'react';

const Testimonial = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const testimonials = [
    { stars: 5, text: "Tôi ấn tượng với chất lượng và sự tỉ mỉ của những món quà. Dịch vụ khách hàng rất tuyệt vời. Tôi chắc chắn sẽ mua lại!", name: "Sarah M." },
    { stars: 5, text: "Món quà được cá nhân hóa làm cho nó trở nên đặc biệt hơn. Tôi rất hài lòng với sản phẩm và sẽ giới thiệu cho bạn bè!", name: "Alex K." },
    { stars: 5, text: "Giao hàng nhanh và đóng gói đẹp mắt. Chắc chắn tôi sẽ đặt hàng lần nữa trong tương lai!", name: "James L." },
    { stars: 5, text: "Tôi rất hài lòng với trải nghiệm mua sắm của mình. Sản phẩm chất lượng cao và dịch vụ khách hàng xuất sắc!", name: "Emma S." },
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section className="happy-customers">
      <div className="container">
        <h2>OUR HAPPY CUSTOMERS</h2>
        <div className="testimonial-container">
          <div className="testimonial-slider" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial">
                <div className="stars">{'★'.repeat(testimonial.stars)}</div>
                <p className="testimonial-text">{testimonial.text}</p>
                <p className="customer-name">{testimonial.name}</p>
              </div>
            ))}
          </div>
          <div className="testimonial-nav">
            <button className="nav-button prev" onClick={prevSlide}>←</button>
            <button className="nav-button next" onClick={nextSlide}>→</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;